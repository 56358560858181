import axios, { AxiosRequestConfig } from 'axios'
import firebase from 'firebase/app'
import 'firebase/auth'

import { settings } from 'config/settings'

interface ApiRequestData extends AxiosRequestConfig {
	path?: string
}
export const apiRequest = async (apiData: ApiRequestData) => {
	const { path, headers, baseURL, ...rest } = apiData
	const { API_BASE_URL, apiDebugging } = settings

	const url = (baseURL || API_BASE_URL) + '/' + path
	let customHeaders = {}
	const authReturn = firebase.auth()
	if (authReturn.currentUser) {
		const { uid } = authReturn.currentUser
		const token = await authReturn.currentUser.getIdToken()
		customHeaders = { uid, token }
	}
	customHeaders = { ...customHeaders, ...headers }
	const apiConfig = { url, headers: customHeaders, ...rest }
	if (apiDebugging) {
		console.log(`API REQUEST - ${path}:`, apiConfig)
	}
	const response = await axios(apiConfig)
	if (apiDebugging) {
		console.log(`API RESPONSE - ${path}:`, response)
	}
	return response
}
